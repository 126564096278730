import { css } from 'styled-components'

const MOBILE_MAX_SIZE = 767
const sizes = {
  mobileOnly: 320,
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  if (label === 'mobileOnly') {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[
          label
        ]}px) and (max-width: ${MOBILE_MAX_SIZE}px) {
        ${css(...args)};
      }
    `
    return acc
  }

  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
