import React from 'react'
import styled, { keyframes } from 'styled-components'

import { media } from '../utils/media'

function generateRandomNumber() {
  const min = 1
  const max = 5

  return Math.floor(Math.random() * (max - min + 1)) + min
}

function getTextShadow(lightColor, middleColor, darkColor) {
  return `
    0.1rem 0rem 0.25rem #${lightColor}, 0.2rem 0rem 0.25rem #${lightColor},
    0.4rem 0rem 0.25rem #${lightColor}, 0.1rem 0rem 0rem #${middleColor},
    0.2rem 0rem 0rem #${middleColor}, 0.4rem 0rem 0rem #${middleColor},
    0.1rem 0rem 0.1rem #${middleColor}, 0.2rem 0rem 0.1rem #${middleColor},
    0.4rem 0rem 0.1rem #${middleColor}, 0.1rem 0rem 2rem #${middleColor},
    0.2rem 0rem 2rem #${middleColor}, 0.4rem 0rem 2rem #${middleColor},
    0.1rem 0rem 1rem #${darkColor}, 0.2rem 0rem 1rem #${darkColor},
    0.4rem 0rem 5rem #${darkColor}, 0.1rem 0rem 5rem #${darkColor},
    0.2rem 0rem 20rem #${darkColor}, 0.4rem 0rem 10rem #${darkColor},
    0.1rem 0rem 10rem #${darkColor}, 0.2rem 0rem 30rem #${darkColor},
    0.4rem 0rem 10rem #${darkColor};
  `
}

const flicker = keyframes`
  0% {
    opacity:0.1;
  }
  2% {
    opacity:1;
  }
  8% {
    opacity:0.1;
  }
  9% {
    opacity:1;
  }
  12% {
    opacity:0.1;
  }
  20% {
    opacity:1;
  }
  25% {
    opacity:0.3;
  }
  30% {
    opacity:1;
  }
  70% {
    opacity:0.7;
  }
  72% {
    opacity:0.2;
  }
  77% {
    opacity:.9;
  }
  100% {
    opacity:.9;
  }
`

const JankyLetter = styled.span`
  animation: ${flicker} ${props => props.animationTime}s linear infinite;
  animation-direction: ${props =>
    props.altDirection ? 'alternate' : 'normal'};
`

const NeonMessageWrapper = styled.div`
  font-family: 'Potra';
  font-size: 3rem;
  padding: 0 10px;
  user-select: none;

  ${media.tablet`
    font-size: 5rem;
  `}

  .under-construction {
    color: #ffd8d8;
    margin-bottom: 50px;
    text-shadow: ${getTextShadow('ffd8d8', 'f25757', 'e50b0b')};
  }

  .check-later {
    color: #b6bfff;
    text-shadow: ${getTextShadow('b6bfff', '384fff', '293aba')};
  }
`

export const UnderConstruction = () => {
  return (
    <NeonMessageWrapper>
      <div className="under-construction">
        Under Co
        <JankyLetter animationTime={generateRandomNumber()} altDirection>
          n
        </JankyLetter>
        struction.
      </div>
      <div className="check-later">
        Ch<JankyLetter animationTime={generateRandomNumber()}>e</JankyLetter>ck
        back later.
      </div>
    </NeonMessageWrapper>
  )
}
